import moment from "moment";

const dateMixin = {
  methods: {
    formatSimpleTimestamp(timestamp) {
      if (!timestamp) return null;
      return (
        moment(timestamp.replace(/ /g, "T")).format("MMMM Do YYYY, h:mm A") ||
        timestamp
      );
    },

    formatDate(timestamp) {
      if (!timestamp) return null;
      return (
        moment(timestamp.replace(/ /g, "T")).format("MMMM D, YYYY") || timestamp
      );
    },

    formatTime(timestamp) {
      if (!timestamp) return null;
      return moment(timestamp.replace(/ /g, "T")).calendar();
    },

    getExactDateFromRange(range) {
      let startDate = null;
      let endDate = null;

      if (range === "15 Days") {
        startDate = moment()
          .subtract(15, "days")
          .format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      }

      if (range === "This Month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      }

      if (range === "Last Month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD ");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      }

      if (range === "3 Months") {
        startDate = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD ");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      }

      return { startDate, endDate };
    },

    getCurrentTimestamp() {
      return moment().format();
    }
  }
};

export default dateMixin;
