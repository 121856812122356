var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-setup',[_c('v-container',{staticClass:"ma-0 pa-0 fill-height px-10 mt-5",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center my-4"},[_c('div',{staticClass:"text-h5 text-color-main font-weight-black mr-4"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer')],1)])],1),_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","round":"","width":"100%"}},[_c('location-user-card'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"rounded-lg mb-5",attrs:{"headers":_vm.headers,"items":_vm.users,"item-key":"id","loading":_vm.loading,"server-items-length":_vm.totalItems,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.paginate]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-items',{staticClass:"align-center"},[_c('v-text-field',{staticClass:"rounded-lg field-width mr-3",staticStyle:{"width":"15rem"},attrs:{"dense":"","hide-details":"","placeholder":"Search","prepend-inner-icon":"mdi-magnify","filled":"","rounded":""},on:{"input":_vm.getUsers},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}}),_c('v-select',{staticClass:"rounded-lg field-width mr-3",staticStyle:{"max-width":"38rem"},attrs:{"dense":"","hide-details":"","filled":"","rounded":"","placeholder":"Filter user location","loading":_vm.loadingLocations,"item-text":"name","item-value":"id","items":_vm.locations,"small-chips":"","multiple":"","clearable":""},on:{"change":_vm.getUsers,"click:clear":function($event){_vm.params.location_ids = null}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{key:index,attrs:{"small":""}},[_vm._v(_vm._s(item.name.split(" ")[1]))])]}}]),model:{value:(_vm.params.location_ids),callback:function ($$v) {_vm.$set(_vm.params, "location_ids", $$v)},expression:"params.location_ids"}})],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-sort")])],1)]}}],null,true)},[_c('span',[_vm._v("Sort")])])]}}])},[_c('v-list',_vm._l((_vm.sortTypes),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.params.sort_by = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var onMenu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":!_vm.dateRange.selectedPreset,"rounded":""}},onMenu),[_c('v-icon',{class:_vm.dateRange.selectedPreset ? 'mr-1' : ''},[_vm._v("mdi-calendar")]),(_vm.dateRange.selectedPreset)?_c('span',{staticClass:"\n                                  text-capitalize\n                                  d-flex\n                                  align-center align-content-center\n                              "},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mr-1"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncateText(_vm.dateRange.selectedPreset))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.dateRange.selectedPreset))])]),_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":_vm.clearSelectedDates}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1):_vm._e()],1)]}}])},[_c('v-list',[_vm._l((_vm.dateRange.presets),function(preset,index){return [_c('v-list-item',{key:index,on:{"click":function($event){return _vm.selectDatePreset(preset)}}},[_vm._v(" "+_vm._s(preset)+" ")])]})],2)],1),_c('create-user-dialog',{attrs:{"eventName":_vm.eventName}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":"","loading":_vm.isExportStart},on:{"click":_vm.exportDonations}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-export")])],1)]}}])},[_c('span',[_vm._v("Export to CSV")])])],1)]},proxy:true},{key:"item.profile_picture",fn:function(ref){
                        var item = ref.item;
return [_c('v-img',{attrs:{"src":item.profile_picture,"max-height":"50","max-width":"50"}})]}},{key:"item.user_type",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-color-main caption text-no-wrap"},[(item.user_type === 'pastor')?_c('v-chip',{attrs:{"dark":"","color":"orange","small":""}},[_vm._v(" Pastor ")]):(item.user_type === 'admin')?_c('v-chip',{attrs:{"dark":"","color":"#06a2de","small":""}},[_vm._v(" Admin ")]):(item.user_type === 'basic_user')?_c('v-chip',{attrs:{"dark":"","color":"#a3aeba","small":""}},[_vm._v(" Basic User ")]):_vm._e()],1)]}},{key:"item.created_at",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("MIXINS_DATE")(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"d-flex"},[_c('create-user-dialog',{attrs:{"itemID":item.id,"eventName":_vm.eventName,"form":item}}),_c('delete-item-dialog',{attrs:{"itemID":item.id,"setup":_vm.setupDeleteDialog,"deleteURL":_vm.componentURL,"event-name":_vm.eventName}})],1)]}}],null,true)})],1)],1)],1)],1),_c('custom-date-range-dialog',{attrs:{"is-open":_vm.isDateRangeDialogOpen,"start-date":_vm.dateRange.dateFrom,"end-date":_vm.dateRange.dateTo,"selected-date-range":_vm.dateRange.selectedPreset},on:{"update:isOpen":function($event){_vm.isDateRangeDialogOpen=$event},"update:is-open":function($event){_vm.isDateRangeDialogOpen=$event},"update:startDate":function($event){return _vm.$set(_vm.dateRange, "dateFrom", $event)},"update:start-date":function($event){return _vm.$set(_vm.dateRange, "dateFrom", $event)},"update:endDate":function($event){return _vm.$set(_vm.dateRange, "dateTo", $event)},"update:end-date":function($event){return _vm.$set(_vm.dateRange, "dateTo", $event)},"update:selectedDateRange":function($event){return _vm.$set(_vm.dateRange, "selectedPreset", $event)},"update:selected-date-range":function($event){return _vm.$set(_vm.dateRange, "selectedPreset", $event)},"onModify":_vm.getUsers}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }