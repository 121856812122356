<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        :outlined="outlined"
        :dense="dense"
        :disabled="disabled"
        :value="formatDate(valueLocal)"
        :rules="rules"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :max="max"
      v-model="valueLocal"
      @input="handleInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import dateMixin from "@/mixins/date";

export default {
  name: "base-date-picker",

  mixins: [dateMixin],

  props: {
    value: String,
    label: String,
    outlined: Boolean,
    dense: Boolean,
    disabled: Boolean,
    rules: Array,
    max: String
  },

  data() {
    return {
      valueLocal: this.value,
      menu: false
    };
  },

  methods: {
    handleInput(e) {
      this.$emit("input", e);
      this.menu = false;
    }
  },

  watch: {
    value(val) {
      this.valueLocal = val;
    }
  }
};
</script>
