import truncate from "truncate";

const utilMixin = {
  methods: {
    formatName({ name, full_name, first_name, last_name }) {
      let displayName;
      if (full_name) {
        displayName = full_name;
      } else if (name) {
        displayName = name;
      } else {
        displayName = `${first_name} ${last_name}`;
      }

      return displayName;
    },

    truncateText(text, length = 10) {
      return truncate(text, length);
    },

    textify(slug) {
      let words = slug.split("_");
      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }

      return words.join(" ");
    },

    formatMoney(money) {
      return new Intl.NumberFormat("nb-NY", {
        style: "currency",
        currency: "NOK",
        currencyDisplay: "narrowSymbol"
      }).format(money);
    },

    removeEmptyProperty(object) {
      return Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(object).filter(([_, v]) => v != null)
      );
    }
  }
};

export default utilMixin;
