<template>
  <v-dialog width="500" persistent v-model="isOpenLocal">
    <v-card>
      <v-card-title>
        <span> Date Range </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="isOpenLocal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <base-date-picker
              label="Start Date *"
              outlined
              v-model="startDateLocal"
            ></base-date-picker>
          </v-col>
          <v-col cols="12">
            <base-date-picker
              label="End Date *"
              outlined
              v-model="endDateLocal"
            ></base-date-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" :disabled="!isFormValid" @click="modify"
          >Modify</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseDatePicker from "@/components/base/DatePicker";
import dateMixin from "@/mixins/date";

export default {
  name: "custom-date-range-dialog",

  mixins: [dateMixin],

  components: { BaseDatePicker },

  props: {
    isOpen: Boolean,
    startDate: [String, null],
    endDate: [String, null],
    selectedDateRange: [String, null]
  },

  data() {
    return {
      isOpenLocal: this.isOpen,
      startDateLocal: null,
      endDateLocal: null
    };
  },

  computed: {
    isFormValid() {
      return this.startDateLocal && this.endDateLocal;
    }
  },

  watch: {
    isOpen(value) {
      this.isOpenLocal = value;
    },
    isOpenLocal(value) {
      this.$emit("update:isOpen", value);
    }
  },

  methods: {
    modify() {
      this.$emit("update:startDate", this.startDateLocal);
      this.$emit("update:endDate", this.endDateLocal);
      this.$emit(
        "update:selectedDateRange",
        `${this.formatDate(this.startDateLocal)} - ${this.formatDate(
          this.endDateLocal
        )}`
      );
      this.isOpenLocal = false;
      this.$emit("onModify");
    }
  }
};
</script>
