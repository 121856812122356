<template>
  <page-setup>
    <v-container fluid class="ma-0 pa-0 fill-height px-10 mt-5">
      <v-row dense>
        <v-col cols="12">
          <div class="d-flex align-center my-4">
            <div class="text-h5 text-color-main font-weight-black mr-4">
              {{ title }}
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-col>
      </v-row>
      <v-card flat round class="rounded-lg" width="100%">
        <location-user-card />
        <v-row dense>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="users"
              item-key="id"
              class="rounded-lg mb-5"
              :loading="loading"
              :server-items-length="totalItems"
              :options.sync="options"
              @update:options="paginate"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-items class="align-center">
                    <v-text-field
                      v-model="params.search"
                      dense
                      hide-details
                      placeholder="Search"
                      class="rounded-lg field-width mr-3"
                      prepend-inner-icon="mdi-magnify"
                      @input="getUsers"
                      filled
                      rounded
                      style="width: 15rem"
                    ></v-text-field>
                    <v-select
                      dense
                      hide-details
                      filled
                      rounded
                      placeholder="Filter user location"
                      :loading="loadingLocations"
                      item-text="name"
                      item-value="id"
                      :items="locations"
                      v-model="params.location_ids"
                      small-chips
                      multiple
                      @change="getUsers"
                      class="rounded-lg field-width mr-3"
                      clearable
                      @click:clear="params.location_ids = null"
                      style="max-width: 38rem"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip small :key="index">{{
                          item.name.split(" ")[1]
                        }}</v-chip>
                      </template>
                    </v-select>
                  </v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on: onMenu }">
                      <v-tooltip bottom>
                        <template
                          v-slot:activator="{
                            on: onTooltip
                          }"
                        >
                          <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
                            <v-icon>mdi-sort</v-icon>
                          </v-btn>
                        </template>
                        <span>Sort</span>
                      </v-tooltip>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in sortTypes"
                        @click="params.sort_by = item.value"
                        :key="index"
                      >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-menu offset-y>
                    <template v-slot:activator="{ on: onMenu }">
                      <v-btn
                        :icon="!dateRange.selectedPreset"
                        rounded
                        v-on="onMenu"
                      >
                        <v-icon :class="dateRange.selectedPreset ? 'mr-1' : ''"
                          >mdi-calendar</v-icon
                        >
                        <span
                          class="
                                    text-capitalize
                                    d-flex
                                    align-center align-content-center
                                "
                          v-if="dateRange.selectedPreset"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span class="mr-1" v-bind="attrs" v-on="on">
                                {{ truncateText(dateRange.selectedPreset) }}
                              </span>
                            </template>
                            <span>{{ dateRange.selectedPreset }}</span>
                          </v-tooltip>

                          <v-btn icon x-small @click="clearSelectedDates"
                            ><v-icon>mdi-close-circle</v-icon></v-btn
                          >
                        </span>
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(preset, index) in dateRange.presets">
                        <v-list-item
                          :key="index"
                          @click="selectDatePreset(preset)"
                        >
                          {{ preset }}
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>

                  <create-user-dialog
                    :eventName="eventName"
                  ></create-user-dialog>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        icon
                        :loading="isExportStart"
                        @click="exportDonations"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-file-export</v-icon>
                      </v-btn>
                    </template>
                    <span>Export to CSV</span>
                  </v-tooltip>
                </v-toolbar>
              </template>

              <template v-slot:[`item.profile_picture`]="{ item }">
                <v-img
                  :src="item.profile_picture"
                  max-height="50"
                  max-width="50"
                ></v-img>
              </template>

              <template v-slot:item.user_type="{ item }">
                <div class="text-color-main caption text-no-wrap">
                  <v-chip
                    dark
                    color="orange"
                    small
                    v-if="item.user_type === 'pastor'"
                  >
                    Pastor
                  </v-chip>
                  <v-chip
                    dark
                    color="#06a2de"
                    small
                    v-else-if="item.user_type === 'admin'"
                  >
                    Admin
                  </v-chip>
                  <v-chip
                    dark
                    color="#a3aeba"
                    small
                    v-else-if="item.user_type === 'basic_user'"
                  >
                    Basic User
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ item.created_at | MIXINS_DATE }}
              </template>
              <template v-slot:item.action="{ item }">
                <span class="d-flex">
                  <create-user-dialog
                    :itemID="item.id"
                    :eventName="eventName"
                    :form="item"
                  >
                  </create-user-dialog>
                  <delete-item-dialog
                    :itemID="item.id"
                    :setup="setupDeleteDialog"
                    :deleteURL="componentURL"
                    :event-name="eventName"
                  >
                  </delete-item-dialog>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <custom-date-range-dialog
      :is-open.sync="isDateRangeDialogOpen"
      :start-date.sync="dateRange.dateFrom"
      :end-date.sync="dateRange.dateTo"
      :selected-date-range.sync="dateRange.selectedPreset"
      @onModify="getUsers"
    ></custom-date-range-dialog>
  </page-setup>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";
import CustomDateRangeDialog from "@/components/custom/DateRangeDialog";
import dateMixin from "@/mixins/date";
import utilMixin from "@/mixins/util";

const userRepository = RepositoryFactory.get("user");
const locationRepository = RepositoryFactory.get("location");

export default {
  components: {
    CustomDateRangeDialog,
    DeleteItemDialog: () =>
      import("@/components/miscellaneous/dialogs/DeleteItemDialog"),
    CreateUserDialog: () => import("../dialogs/CreateUserDialog"),
    LocationUserCard: () => import("../cards/LocationUserCard")
  },

  mixins: [dateMixin, utilMixin],

  data: () => ({
    title: "Users",
    headers: [
      { text: "Picture", value: "profile_picture", sortable: false },
      { text: "Name", value: "name", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Location", value: "location", sortable: false },
      { text: "User Type", value: "user_type", sortable: false },
      { text: "Registration Date", value: "created_at", sortable: false },
      { text: "Action", value: "action", sortable: false }
    ],
    users: [],
    options: {
      page: 1,
      itemsPerPage: 15
    },
    params: {
      page: 1,
      per_page: 15,
      search: "",
      sort_by: null
    },
    totalItems: 0,
    loading: false,
    componentURL: "api/users",
    setupDeleteDialog: {
      button: {
        rounded: true,
        small: true,
        depressed: true,
        dark: true,
        class: "mx-1",
        icon: false,
        label: "Delete",
        isIcon: false
      },
      card: {
        title: "Users Delete Item"
      }
    },
    eventName: "reloadItems_users",
    locations: [],
    loadingLocations: false,
    isExportStart: false,
    sortTypes: [
      {
        text: "Newest",
        value: "desc"
      },
      {
        text: "Oldest",
        value: "asc"
      }
    ],
    dateRange: {
      presets: ["15 Days", "This Month", "3 Months", "Custom Dates"],
      selectedPreset: null,
      dateFrom: null,
      dateTo: null
    },
    isDateRangeDialogOpen: false
  }),

  computed: {
    joinLocationIds() {
      return this.params.location_ids ? this.params.location_ids.join(",") : "";
    }
  },

  watch: {
    async "params.sort_by"() {
      this.params.page = 1;
      await this.getUsers();
    }
  },

  methods: {
    async getData() {
      await this.getLocations();
      await this.getUsers();
    },

    async paginate() {
      this.params.page = this.options.page;
      this.params.per_page = this.options.itemsPerPage;

      await this.getData();
    },

    async getUsers() {
      let params = { ...this.params };
      params.location_ids = this.joinLocationIds;

      if (this.dateRange.dateFrom) params.date_from = this.dateRange.dateFrom;
      if (this.dateRange.dateFrom && this.dateRange.dateTo)
        params.date_to = this.dateRange.dateTo;

      this.loading = true;

      userRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: data }
          } = response;

          let items = data.data.map(item => {
            return {
              id: item.id,
              profile_picture: item.profile_picture_url,
              name: `${item.first_name} ${item.last_name}`,
              first_name: item.first_name,
              last_name: item.last_name,
              email: item.email,
              location_id: item.location_id,
              location: item.location?.name || null,
              user_type: item.user_type,
              created_at: item.created_at
            };
          });

          this.users = items;

          this.totalItems = data.total;
        })
        .catch(() => {
          this.users = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reloadItemsDeleteEventBus() {
      DELETE_ITEM_EVENT_BUS.$off(this.eventName);
      DELETE_ITEM_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },

    reloadItemsUsersEventBus() {
      GLOBAL_EVENT_BUS.$off(this.eventName);
      GLOBAL_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },

    setupEventBus() {
      this.reloadItemsUsersEventBus();
      this.reloadItemsDeleteEventBus();
    },

    async getLocations() {
      this.loadingLocations = true;

      let params = {
        full_data: true
      };

      await locationRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: items }
          } = response;

          this.locations = items;
        })
        .catch(() => {
          this.locations = [];
        })
        .finally(() => {
          this.loadingLocations = false;
        });
    },

    async exportDonations() {
      let location_ids = this.joinLocationIds;
      let { search } = this.params;
      this.isExportStart = true;
      await userRepository
        .exportToCsv({
          params: {
            search,
            location_ids
          }
        })
        .then(response => {
          let blob = new Blob([response.data], { type: "application/csv" });
          let fileURL = window.URL.createObjectURL(blob);
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `user_lists.csv`);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      this.isExportStart = false;
    },

    async selectDatePreset(preset) {
      this.dateRange.selectedPreset = preset;
      if (preset === "Custom Dates") return (this.isDateRangeDialogOpen = true);
      else if (this.dateRange.presets.includes(preset)) {
        const { startDate, endDate } = this.getExactDateFromRange(preset);
        this.dateRange.dateFrom = startDate;
        this.dateRange.dateTo = endDate;
        await this.getUsers();
      }
    },

    async clearSelectedDates() {
      this.dateRange.selectedPreset = null;
      this.dateRange.dateFrom = null;
      this.dateRange.dateTo = null;
      await this.getUsers();
    }
  },

  mounted() {
    this.setupEventBus();
  }
};
</script>
